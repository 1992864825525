export default class PlatformUtil {

    public static IsDevelopment(): boolean {
        return process && process.env && process.env.NODE_ENV === 'development';
    }

    public static SanitizeApiUrl(url: string): string {
        if (!PlatformUtil.IsDevelopment()) {
            url += '.json';
        }
        return url;
    }
}
