import * as React from 'react';
import { format, formatDistanceToNow } from 'date-fns';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Row, Col, ButtonGroup, Input, Button, Form, FormGroup } from 'reactstrap';
import { ApplicationState } from '../../store';
import * as LoginStore from '../../store/Login';
// import loginImage from './images/bee-dialog-login.png';
// import loginImageResponsive from './images/bee-dialog.png';
import MessagesConversation from '../../store/MessagesConversation';
import MessagesViewItem from './MessagesViewItem';
import SidebarLeft from '../stream-items/SidebarLeft';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './MessagesView.css';
import App from '../../App';
import MessagesConversationItem from '../../store/MessagesConversationItem';

//type MessagesViewProps =
//    //SubscribeStore.SubscribeState &
//    //typeof SubscribeStore.actionCreators &
//    RouteComponentProps<{}>;


export default class MessagesView { // extends React.PureComponent<{}, {}> {
    private static reversed = new Map<number, boolean>();

    public static render(conversations: MessagesConversation[], selectedConversationIndex: string, userName: string) {
        let selectedIndex: number = parseInt(selectedConversationIndex, 10);
        selectedIndex = isNaN(selectedIndex) ? -1 : selectedIndex;

        if (conversations && conversations[selectedIndex] && conversations[selectedIndex].conversationItems) {
            const unreadIndex = Math.floor(Math.random() * conversations[selectedIndex].conversationItems.length);
            return (
                <>
                    <div className="messages-view" id="divMessagesView">
                        <div className="messages-view-item-wrapper">
                            {conversations[selectedIndex].conversationItems.map((item: MessagesConversationItem, index: number) => {
                                return (
                                    MessagesViewItem.render(item, userName, !!index && index === unreadIndex)
                                );
                            })}

                        </div>

                        <p>&nbsp;</p>
                    </div>

                    <div className="messages-view-form">
                        <Form inline id="formMessagesView">
                            <FormGroup>
                                <Input type="text" name="message" id="txtMessage" className=" container-fluid " placeholder="What's happening?" />
                                <Button id="cmdSend" color="primary" title="Click to send your message"><i className="bi bi-send"></i></Button>
                            </FormGroup>
                        </Form>
                    </div>

                </>
            );
        } else {
            return(
                <div className="messages-view">
                    <div className="messages-view-empty">
                        <h2><i className="bi bi-arrow-bar-left"></i> Select a Message</h2>
                        <p>Choose an existing conversation, or start a new conversation.</p>
                        <p><Button id="cmdNewConversationWhenEmpty" color="primary" title="Start a New Conversation"><i className="bi bi-chat-dots-fill"></i>&nbsp;&nbsp;Start a new Conversation</Button></p>
                    </div>
                </div>
            );
        }


    };
};

//export default connect(
//    (state: ApplicationState) => state.login,
//    LoginStore.actionCreators
//)(MessagesView);
