import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Row, Col, ButtonGroup, Input } from 'reactstrap';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import * as MessagesStore from '../store/Messages';
import SidebarLeft from './stream-items/SidebarLeft';
import MessagesList from './message-items/MessagesList';
import MessagesView from './message-items/MessagesView';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Messages.css';
import App from '../App';
import AuthUtil from '../store/AuthUtil';

import { Redirect } from 'react-router-dom';

type MessagesProps =
    MessagesStore.MessagesState &
    typeof MessagesStore.actionCreators &
    RouteComponentProps<{ startIndex: string, userName: string, selectedConversationIndex: string }>;

class FetchData extends React.PureComponent<MessagesProps> {
    constructor(props: any) {
        super(props);
        this.selectConversation = this.selectConversation.bind(this);
    }

    protected scrollToBottom() {
        const messagesViewDiv = document.querySelector("#divMessagesView");
        if (messagesViewDiv) {
            setTimeout(() => { messagesViewDiv.scrollTop = messagesViewDiv.scrollHeight; }, 10);
            
        }
    }

    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
        this.scrollToBottom();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
        this.scrollToBottom();
    }

    protected ensureDataFetched() {
        const startIndex = parseInt(this.props.match.params.startIndex, 10) || 0;
        const userName = this.props.match.params.userName;
        // const apiUri = `/user/${userName}/messages/${startIndex}`;
        this.props.requestConversations(startIndex, userName);
    }

    public selectConversation(e: any, index: number) {
        this.props.selectConversation(""+index);
    }

    static count = 0;

    public render() {
        if (AuthUtil.RequireAuth()) {
            return (
                <Row className='tall'>
                    <Col md='3'> <SidebarLeft> </SidebarLeft> </Col>
                    <Col md='4' className='messages-list-container'> {MessagesList.render(this.props.conversations, this.selectConversation, this.props.selectedConversationIndex, this.props.userName)} </Col>
                    <Col md='5' className='messages-view-container'> {MessagesView.render(this.props.conversations, this.props.selectedConversationIndex, this.props.userName)} </Col>
                </Row>
            );
        } else {
            return (<Redirect to={'/Logout'} />);
        }
    };
};

export default connect(
    (state: ApplicationState) => state.conversations,
    MessagesStore.actionCreators
)(FetchData as any);
