import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import MessagesConversation from './MessagesConversation';

import AuthUtil from './AuthUtil';
import PlatformUtil from './PlatformUtil';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface MessagesState {
    isLoading: boolean;
    startIndex?: number;
    conversations: MessagesConversation[];
    userName: string;
    selectedConversationIndex: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

const REQUEST_MESSAGES = "REQUEST_MESSAGES";
const RECEIVE_MESSAGES = "RECEIVE_MESSAGES";
const SELECT_CONVERSATION = "SELECT_CONVERSATION";

interface RequestMessagesAction {
    type: 'REQUEST_MESSAGES';
    startIndex: number;
    userName: string;
}

interface ReceiveMessagesAction {
    type: 'RECEIVE_MESSAGES';
    startIndex: number;
    conversations: MessagesConversation[];
    userName: string;
}

interface SelectConversationAction {
    type: 'SELECT_CONVERSATION';
    selectedConversationIndex: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestMessagesAction | ReceiveMessagesAction | SelectConversationAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestConversations: (startIndex: number, userName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {

        userName = AuthUtil.getUserName();

        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.conversations && startIndex !== appState.conversations.startIndex) {
            // const fetchUri = `/api/user/${userName}/messages/${startIndex}.json`;
            const fetchUri = PlatformUtil.SanitizeApiUrl(`/api/user/${userName}/messages/${startIndex || 0}`);

            fetch(fetchUri)
                .then(response => {
                    return response.json() as Promise<MessagesConversation[]> })
                .then(data => {
                    for (let i = 0; i < data.length; i++) {
                        data[i].conversationItems = data[i].conversationItems.reverse();
                    }
                    dispatch({ type: RECEIVE_MESSAGES, startIndex: startIndex, userName: userName, conversations: data });
                });

            dispatch({ type: REQUEST_MESSAGES, startIndex: startIndex, userName: userName });
        }
    },

    selectConversation: (selectedConversationIndex: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        const appState = getState();
        if(appState && appState.conversations && appState.conversations.selectedConversationIndex !== selectedConversationIndex) {
            dispatch({ type: SELECT_CONVERSATION, selectedConversationIndex });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: MessagesState = { conversations: [] as MessagesConversation[], userName: "", isLoading: false, selectedConversationIndex: "-1" };

export const reducer: Reducer<MessagesState> = (state: MessagesState | undefined, incomingAction: Action): MessagesState => {

    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case REQUEST_MESSAGES:
            return {
                startIndex: action.startIndex,
                conversations: state.conversations,
                userName: action.userName,
                isLoading: true,
                selectedConversationIndex: "-1",
            };
        case RECEIVE_MESSAGES:
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            if (action.startIndex === state.startIndex) {
                return {
                    startIndex: action.startIndex,
                    conversations: action.conversations,
                    userName: action.userName,
                    isLoading: false,
                    selectedConversationIndex: "-1",
                };
            }
            break;
        case SELECT_CONVERSATION:
            if(action.selectedConversationIndex !== state.selectedConversationIndex) {
                const ndx = parseInt(action.selectedConversationIndex, 10);
                const index: number = isNaN(ndx) ? -1 : ndx;
                if(index >= 0) {
                    state.conversations[index].isUnread = false;
                }
                return {
                    startIndex: state.startIndex,
                    conversations: state.conversations,
                    userName: state.userName,
                    isLoading: state.isLoading,
                    selectedConversationIndex: action.selectedConversationIndex,
                };
            }
            break;
    }

    return state;
};

