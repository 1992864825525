import * as React from 'react';
import { Button, Nav, NavLink, NavItem, Dropdown, DropdownItem, DropdownMenu, DropdownToggle } from 'reactstrap';
//import 'bootstrap-icons/font/bootstrap-icons.css';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import NewStreamItemModal from './NewStreamItemModal'
import './SidebarLeft.css';
import AuthUtil from '../../store/AuthUtil';

export default class SidebarLeft extends React.Component<{}, { show: boolean, dropDownOpen1: boolean, dropDownOpen2: boolean }> {
    constructor(props: any) {
        super(props);

        this.state = {
            show: false,
            dropDownOpen1: false,
            dropDownOpen2: false,
        };

        this.handlePillClick = this.handlePillClick.bind(this);
        this.handleClick = this.handleClick.bind(this);
        this.hideModal = this.hideModal.bind(this);
        this.toggle1 = this.toggle1.bind(this);
        this.toggle2 = this.toggle2.bind(this);
    }

    handleClick(e: any) {
        (e.target as HTMLElement).blur();
        this.setState({ show: true });
    }

    hideModal() {
        this.setState({ show: false });
    }

    public handlePillClick(e: any): void {
        e.target.blur();
    }

    toggle1() {
        this.setState({
            dropDownOpen1: !this.state.dropDownOpen1
        });
    }

    toggle2() {
        this.setState({
            dropDownOpen2: !this.state.dropDownOpen2
        });
    }

    public render(config?: any): React.ReactFragment {
        // const pathname = getState() ?.router?.location?.pathname;
        const isActiveExplore: boolean =
            window.location.href.endsWith("/explore") || window.location.href.endsWith("/local");
            // ["/explore", "/local"].indexOf(pathname) >= 0;
        const isActiveLinks: boolean =
            window.location.href.endsWith("/bookmarks") || window.location.href.endsWith("/lists");
            // ["/bookmarks", "/lists"].indexOf(pathname) >= 0;

        return (
            <div className='sidebar sidebar-left'>
                <Nav pills vertical>
                    <NavItem key={"left-nav-buzz"}><NavLink tag={RRNavLink} to="/buzz" activeClassName="active btn-primary " onClick={this.handlePillClick}><i className="bi bi-newspaper">&nbsp;</i> Buzz</NavLink></NavItem>

                    <Dropdown nav isOpen={this.state.dropDownOpen1} toggle={this.toggle1} className={AuthUtil.HideClassNameIfNotLoggedIn()}>
                        <DropdownToggle nav caret className={isActiveExplore ? "active btn-primary " : ""}>
                            <i className="bi bi-compass">&nbsp;</i> Explore
                        </DropdownToggle>
                        <DropdownMenu>
                            {/* <DropdownItem header>Header</DropdownItem> */}
                            {/* <DropdownItem disabled>Action</DropdownItem> */}
                            {/* <DropdownItem divider /> */}
                            <DropdownItem>
                                <NavItem key={"left-nav-explore"}><NavLink tag={RRNavLink} to="/explore" activeClassName="active btn-primary " onClick={this.handlePillClick}><i className="bi bi-globe">&nbsp;</i> Globally</NavLink></NavItem>
                            </DropdownItem>
                            <DropdownItem>
                                <NavItem key={"left-nav-local"}><NavLink tag={RRNavLink} to="/local" activeClassName="active btn-primary " onClick={this.handlePillClick}><i className="bi bi-geo-alt">&nbsp;</i> Locally</NavLink></NavItem>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                    <Dropdown nav isOpen={this.state.dropDownOpen2} toggle={this.toggle2} className={AuthUtil.HideClassNameIfNotLoggedIn()}>
                        <DropdownToggle nav caret className={isActiveLinks ? "active btn-primary " : ""}>
                            <i className="bi bi-box">&nbsp;</i> My Links
                        </DropdownToggle>
                        <DropdownMenu>
                            {/* <DropdownItem header>Header</DropdownItem> */}
                            {/* <DropdownItem disabled>Action</DropdownItem> */}
                            {/* <DropdownItem divider /> */}
                            <DropdownItem>
                                <NavItem key={"left-nav-bookmarks"}><NavLink tag={RRNavLink} to="/bookmarks" activeClassName="active btn-primary " onClick={this.handlePillClick}><i className="bi bi-bookmark-star">&nbsp;</i> Bookmarks</NavLink></NavItem>
                            </DropdownItem>
                            <DropdownItem>
                                <NavItem key={"left-nav-lists"}><NavLink tag={RRNavLink} to="/lists" activeClassName="active btn-primary " onClick={this.handlePillClick}><i className="bi bi-journal-bookmark-fill">&nbsp;</i> Lists</NavLink></NavItem>
                            </DropdownItem>
                        </DropdownMenu>
                    </Dropdown>

                    <NavItem key={"left-nav-profile"}><NavLink tag={RRNavLink} to={`/user/${AuthUtil.getUserName()}`} className={AuthUtil.HideClassNameIfNotLoggedIn()} activeClassName="active btn-primary " onClick={this.handlePillClick}><i className="bi bi-person-square">&nbsp;</i> My Profile</NavLink></NavItem>
                    <NavItem key={"left-nav-messages"}><NavLink tag={RRNavLink} to="/messages" className={AuthUtil.HideClassNameIfNotLoggedIn()} activeClassName="active btn-primary " onClick={this.handlePillClick}><i className="bi bi-postcard">&nbsp;</i> Messages</NavLink></NavItem>

                    <NavItem key={"left-nav-settings"}><NavLink tag={RRNavLink} to="/settings" className={AuthUtil.HideClassNameIfNotLoggedIn()} activeClassName="active btn-primary " onClick={this.handlePillClick}><i className="bi bi-gear">&nbsp;</i> Settings</NavLink></NavItem>
                    <NavItem key={"left-nav-analytics"}><NavLink tag={RRNavLink} to="/analytics" className={AuthUtil.HideClassNameIfNotLoggedIn()} activeClassName="active btn-primary " onClick={this.handlePillClick}><i className="bi bi-graph-up-arrow">&nbsp;</i> Analytics</NavLink></NavItem>
                    <NavItem key={"left-nav-subscribe"}><NavLink tag={RRNavLink} to="/subscribe" className={AuthUtil.HideClassNameIfNotLoggedIn()} activeClassName="active btn-primary " onClick={this.handlePillClick}><i className="bi bi-cash-coin">&nbsp;</i> Subscribe</NavLink></NavItem>

                    <NavItem key={"left-nav-login"}><NavLink tag={RRNavLink} to="/" className={AuthUtil.HideClassNameIfLoggedIn()} activeClassName="xx-active xx-btn-primary " onClick={this.handlePillClick}><i className="bi bi-person-circle">&nbsp;</i> Login</NavLink></NavItem>
                    <NavItem key={"left-nav-logout"}><NavLink tag={RRNavLink} to="/logout" className={AuthUtil.HideClassNameIfNotLoggedIn()} activeClassName="xx-active xx-btn-primary " onClick={this.handlePillClick}><i className="bi bi-box-arrow-left">&nbsp;</i> Logout</NavLink></NavItem>
                </Nav>
                
                <hr className={AuthUtil.HideClassNameIfNotLoggedIn()} />
                <button type="button"
                    className={AuthUtil.HideClassNameIfNotLoggedIn() + " btn btn-primary btn-lg btn-rounded"}
                    onClick={this.handleClick}>
                    Say Something
                    </button>
                <hr className={AuthUtil.HideClassNameIfNotLoggedIn()} />
                {/* <p>{JSON.stringify(this.props)}</p> */}
                <NewStreamItemModal show={this.state.show} value="" hideModal={this.hideModal} />
            </div>
        );
    }
}

