import * as React from 'react';
import { Route, Switch } from 'react-router';
// [TODO: v6 upgrade] import { Outlet } from 'react-router-dom';
import Layout from './components/Layout';
import Login from './components/Login';
// import Logout from './components/Logout';
import Messages from './components/Messages';
import Subscribe from './components/Subscribe';
import ItemStreamBookmarks from './components/ItemStreamBookmarks';
import ItemStreamBuzz from './components/ItemStreamBuzz';
import ItemStreamExplore from './components/ItemStreamExplore';
import ItemStreamLists from './components/ItemStreamLists';
import ItemStreamLocal from './components/ItemStreamLocal';
import ItemStreamProfile from './components/ItemStreamProfile';
import ContentPrivacy from './components/ContentPrivacy';
import ContentTerms from './components/ContentTerms';
import ContentAbout from './components/ContentAbout';
import ContentCommunity from './components/ContentCommunity';
import NotFound from './components/NotFound';
// import AuthUtil from './store/AuthUtil'

import Logout from './components/Logout';

import './custom.css';
import './App.css';

const RedirectToBlog = () => { window.location.href = "https://beebee.social/blog/"; return null; }
const RedirectToHelp = () => { window.location.href = "https://beebee.social/help/"; return null; }
{ /* const RedirectToApi = () => { window.location.href = "https://beebee.social/api/user/undefined/buzz/0.json"; return null; } */ }

export default () => (
    <Layout>
        <Switch>
            <Route exact path='/' component={Login} />
            <Route exact path='/logout' component={Logout} />git stat

            <Route exact path='/messages' component={Messages} />
            <Route exact path='/subscribe' component={Subscribe} />

            <Route exact path='/buzz' component={ItemStreamBuzz} />
            <Route exact path='/explore' component={ItemStreamExplore} />
            <Route exact path='/local' component={ItemStreamLocal} />
            <Route exact path='/bookmarks' component={ItemStreamBookmarks} />
            <Route exact path='/lists' component={ItemStreamLists} />
            <Route exact path='/user/:userName' component={ItemStreamProfile} />

            <Route exact path='/content/privacy' component={ContentPrivacy} />
            <Route exact path='/content/terms' component={ContentTerms} />
            <Route exact path='/content/about' component={ContentAbout} />
            <Route exact path='/content/community' component={ContentCommunity} />

            <Route exact path='/blog' render={RedirectToBlog} />
            <Route exact path='/help' render={RedirectToHelp} />

            <Route><NotFound/></Route>
        </Switch>
    </Layout>
);
