import * as React from 'react';
import { Row, Col, UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem, Button } from 'reactstrap';
import { Link, NavLink as RRNavLink } from 'react-router-dom';
import './SidebarRight.css';
import StreamItem from '../../store/StreamItem';

const renderWhoToFollow = (items: StreamItem[], userName: string): React.ReactFragment => {

    const itemGuids = [] as string[];
    const suggested = [] as StreamItem[];

    for (const item of items) {
        if (item.userName !== userName) {
            suggested.push(item);
            itemGuids.push(item.userName);
        }
        if (itemGuids.length >= 5) { break; }
    }

    return (
        <div>

            <div className="sidebar-right container-fluid">

                <Row className="no-gutters title-row">
                    <Col md="12"><span className="item-title bg-light">Who to Follow</span></Col>
                </Row>

                {suggested.map((item: StreamItem, index: number) => {

                    // TODO: Replace this mess with a new control or two (Issues #11, #13)
                    const rand: boolean = Math.round(Math.random()) === 1;
                    const followsYouClass = index % 2 === 1 ? "" : " item-hide ";
                    const followsYouText = rand ? "Unfollow" : "Follow";
                    const followsYouOutline = rand ? true : false;
                    const followsYouColor = rand ? "danger" : "primary";
                    const promotedIcon: string = (index === 0) ? " bi bi-arrow-up-right-square " : "";
                    const promotedText: string = (index === 0) ? " Promoted by " : "";
                    const promotedBold: string = (index === 0) ? " item-user-name-promoted " : "";


                    return (
                        <Row className="no-gutters" key={item.id + "-who"}>
                            <Col md="2">
                                <img className='item-avatar' alt='user avatar' src={item.avatarDataUri} />
                            </Col>
                            <Col md="8">
                                <div className={'item-display-name'}><a href={`/user/${item.userName}`}>{item.displayName}</a></div>
                                <div className={`item-user-name ${promotedBold}`}><i className={promotedIcon}></i>{promotedText}<a href={`/user/${item.userName}`}>@{item.userName}</a></div>
                                <div className='item-follows-you-div'><span className={`item-follows-you-span ${followsYouClass}`}>Follows You</span></div>
                            </Col>
                            <Col md="2">
                                <Button
                                    size="sm"
                                    outline={followsYouOutline}
                                    color={followsYouColor}
                                    className=" item-follow-button "
                                    onClick={(e) => { }}>
                                    {followsYouText}
                                </Button>
                            </Col>
                        </Row>
                    );
                })}

                <Row className="no-gutters last-row" key={"content-links"}>
                    <Col md="12">
                        <Link to="/content/privacy">Privacy</Link>&nbsp;&middot;&nbsp;
                        <Link to="/content/terms">Terms</Link>&nbsp;&middot;&nbsp;
                        <Link to="/content/about">About</Link>&nbsp;&middot;&nbsp;
                        &copy; 2022 <a href="http://ososoft.net" target="_blank"> Ososoft, LLC</a>
                    </Col>
                </Row>

            </div>

        </div>
    );
};

export default class SidebarRight {
    public static render(items: StreamItem[], userName: string): React.ReactFragment {
        return (
            <div className='sidebar sidebar-right'>
                { renderWhoToFollow(items, userName) }
            </div>
        );
    }
}
