import { Action, Reducer } from 'redux';
//import { AppThunkAction } from './';
import AuthUtil from './AuthUtil';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface LoginState {
    isLoggedIn: boolean;
    userName: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.
// Use @typeName and isActionType for type detection that works even after serialization/deserialization.

export interface LoginAction { type: 'LOGIN' }
export interface LogoutAction { type: 'LOGOUT' }

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
export type KnownAction = LoginAction | LogoutAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    doLogin: (e: any) => {
        blurControl(e);
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        const txtUser = document.querySelector("#txtUser");
        const userName = (!!txtUser) ? (txtUser as HTMLInputElement).value : "";
        const login = !!userName;
        return ({ type: 'LOGIN', isLoggedIn: login, userName: userName } as LoginAction);
    },

    doLogout: () => {
        document.body.scrollTop = document.documentElement.scrollTop = 0;
        return ({ type: 'LOGOUT', isLoggedIn: false, userName: "" } as LogoutAction);
    }
};

const blurControl = (e?: any) => {
    if (e) {
        if (e.blur) {
            e.blur();
        } else if (e.parentElement && e.parentElement.blur) {
            e.parentElement.blur();
        }
    }
}

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

export const reducer: Reducer<LoginState> = (state: LoginState | undefined, incomingAction: Action): LoginState => {
    if (state === undefined) {
        //AuthUtil.Logout();
        return { isLoggedIn: false, userName: "" };
    }

    const action = incomingAction as KnownAction;
    const actionAny = action as any;

    switch (action.type) {
        case 'LOGIN':
            const userName = actionAny.userName;
            const isLoggedIn = !!userName;
            if(isLoggedIn) {
                AuthUtil.Login(userName);
            } else {
                AuthUtil.Logout();
            }
            return { isLoggedIn: isLoggedIn, userName: userName };
        case 'LOGOUT':
            const newState = { isLoggedIn: false, userName: "" };
            AuthUtil.Logout();
            return newState;
        default:
            return state;
    }

};
