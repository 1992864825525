import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Row, Col, ButtonGroup, Input } from 'reactstrap';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
// import loginImage from './images/bee-dialog-login.png';
// import loginImageResponsive from './images/bee-dialog.png';
import SidebarLeft from './stream-items/SidebarLeft';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Subscribe.css';
import App from '../App';

type SubscribeProps =
    //SubscribeStore.SubscribeState &
    //typeof SubscribeStore.actionCreators &
    RouteComponentProps<{}>;

class Subscribe extends React.PureComponent<SubscribeProps> {
    public render() {
        return (
            <>
            {/* <div className="subscriptions-page "> */}
                <Row className='tall'>
                    <Col md='3'> <SidebarLeft></SidebarLeft> </Col>
                    <Col md='9'>
                        <Row>
                            <Col md="12">
                                <div className="content-head content-fluid">
                                    <h1>Simple Pricing. Starting at Free.</h1>
                                    <p>Content consumers get a free ride. As do the majority of content producers. For the most part, we cover expenses like server and bandwidth costs with ads and promoted posts. But we offer many more features for those that want to take things to the next level.</p>
                                    <p>&nbsp;</p>
                                </div>
                                <Row noGutters>
                                    <Col md="2"><div className=" content-col-head-1 ">
                                        <p className="content-col-title">FREE</p>
                                        <p className="content-col-price">$0</p>
                                        <p className="content-col-price-for">single user<br/>paid annually</p>
                                    </div></Col>
                                    <Col md="2"><div className=" content-col-head-2 ">
                                        <p className="content-col-title">FAMILY</p>
                                        <p className="content-col-price">$5</p>
                                        <p className="content-col-price-for">per family, per month<br />paid annually</p>
                                    </div></Col>
                                    <Col md="2"><div className=" content-col-head-3 ">
                                        <p className="content-col-title">GROUP</p>
                                        <p className="content-col-price">$10</p>
                                        <p className="content-col-price-for">per admin, per month<br />paid annually</p>
                                    </div></Col>
                                    <Col md="2"><div className=" content-col-head-4 ">
                                        <p className="content-col-title">BRAND</p>
                                        <p className="content-col-price">$15</p>
                                        <p className="content-col-price-for">per admin, per month<br />paid annually</p>
                                    </div></Col>
                                    <Col md="2"><div className=" content-col-head-5 ">
                                        <p className="content-col-title">TEAM</p>
                                        <p className="content-col-price">$20</p>
                                        <p className="content-col-price-for">per admin, per month<br />paid annually</p>
                                    </div></Col>
                                    <Col md="2"><div className=" content-col-head-6 ">
                                        <p className="content-col-title">BUSINESS</p>
                                        <p className="content-col-price">$25</p>
                                        <p className="content-col-price-for">per admin, per month<br />paid annually</p>
                                    </div></Col>
                                </Row>
                                <Row noGutters>
                                    <Col md="2"><div className="content-col-1">
                                        <button className="btn btn-secondary subscribe-button disabled">
                                            <i className="bi bi-cash-coin">&nbsp;</i> Select
                                        </button>
                                        <hr/>
                                        <p>Ad-Supported</p>
                                    </div></Col>
                                    <Col md="2"><div className="content-col-2">
                                        <button className="btn btn-primary subscribe-button">
                                            <i className="bi bi-cash-coin">&nbsp;</i> Select
                                        </button>
                                        <hr />
                                        <p>No Ads for Family</p>
                                        <p>Scheduled Posts</p>
                                        <p>Manage up to Six Family Members</p>
                                        <p>One Free One-Week Promoted Post per Quarter</p>
                                    </div></Col>
                                    <Col md="2"><div className="content-col-3">
                                        <button className="btn btn-primary subscribe-button">
                                            <i className="bi bi-cash-coin">&nbsp;</i> Select
                                        </button>
                                        <hr />
                                        <p>No Ads for Admins</p>
                                        <p>Scheduled Posts</p>
                                        <p>Manage up to<br/>Three Groups</p>
                                        <p>One Free One-Week Promoted Post per Quarter</p>
                                        <p>One Free One-Week Promoted Group per Quarter</p>
                                        <p>5,000 Max Members per Group</p>
                                        <p>10&nbsp;Pages, 1&nbsp;FAQ, 1&nbsp;Blog</p>
                                    </div></Col>
                                    <Col md="2"><div className="content-col-4">
                                        <button className="btn btn-primary subscribe-button">
                                            <i className="bi bi-cash-coin">&nbsp;</i> Select
                                        </button>
                                        <hr />
                                        <p>No Ads for Admins</p>
                                        <p>Scheduled Posts</p>
                                        <p>Manage up to<br />Three Brands</p>
                                        <p>One Free One-Week Promoted Post per Quarter</p>
                                        <p>One Free One-Week Promoted Brand per Quarter</p>
                                        <p>Unlimited Brand Members</p>
                                        <p>20&nbsp;Pages, 5&nbsp;FAQs, 1&nbsp;Blog&nbsp;per&nbsp;Admin</p>
                                        <p>One Free One-Week Ad per Quarter</p>
                                    </div></Col>
                                    <Col md="2"><div className="content-col-5">
                                        <button className="btn btn-primary subscribe-button">
                                            <i className="bi bi-cash-coin">&nbsp;</i> Select
                                        </button>
                                        <hr />
                                        <p>No Ads for Members</p>
                                        <p>Scheduled Posts</p>
                                        <p>Manage up to<br />Five Teams</p>
                                        <p>One Free One-Week Promoted Post per Month</p>
                                        <p>One Free One-Week Promoted Team per Month</p>
                                        <p>Unlimited Team Members</p>
                                        <p>40&nbsp;Pages, 10&nbsp;FAQs, 1&nbsp;Blog&nbsp;per&nbsp;Member</p>
                                        <p>One Free One-Week Ad per Quarter</p>
                                        <p>Private Content</p>
                                        <p>Dedicated Support</p>
                                    </div></Col>
                                    <Col md="2"><div className="content-col-6">
                                        <button className="btn btn-primary subscribe-button">
                                            <i className="bi bi-cash-coin">&nbsp;</i> Select
                                        </button>
                                        <hr />
                                        <p>No Ads for Members</p>
                                        <p>Scheduled Posts</p>
                                        <p>Manage up to<br />Five Businesses</p>
                                        <p>One Free One-Week Promoted Post per Month</p>
                                        <p>One Free One-Week Promoted Business per Month</p>
                                        <p>Unlimited Team Members</p>
                                        <p>40&nbsp;Pages, 10&nbsp;FAQs, 1&nbsp;Blog&nbsp;per&nbsp;Member</p>
                                        <p>One Free One-Week Ad per Month</p>
                                        <p>Private Content</p>
                                        <p>Dedicated Support</p>
                                        <p>Custom Domain</p>
                                    </div></Col>
                                </Row>

                                <div className="content-head content-fluid">
                                    <p>&nbsp;</p>
                                    <p>&nbsp;</p>
                                </div>

                            </Col>
                        </Row>
                    </Col>
                </Row >
            {/* </div> */ }
            </>
        );

    };
};

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Subscribe);
