export const PRIVACY_MARKDOWN = `
# Privacy Policy

Last revised on September 1st, 2022

### The Gist

Ososoft, LLC will collect certain non-personally-identifiable information about you as you use our sites. We may use this data to better understand our users. We can also publish this data, but the data will be about a large group of users, not individuals.

We will also ask you to provide personal information, but you'll always be able to opt out. If you give us personal information, we won't do anything evil with it.

We can also use cookies (and/or local storage), but you can choose not to accept these.

That's the basic idea, but you must read through the entire Privacy Policy below and agree with all the details before you use any of our sites.

### Questions

If you have question about this Privacy Policy, please contact us at [hello@ososoft.net](mailto:hello@ososoft.net).

### Visitors

Like most website operators, Ososoft, LLC collects non-personally-identifying information of the sort that web browsers and servers typically make available, such as the browser type, language preference, referring site, and the date and time of each visitor request. Ososoft, LLC's purpose in collecting non-personally identifying information is to better understand how Ososoft, LLC's visitors use its website. From time to time, Ososoft, LLC may release non-personally-identifying information in the aggregate, e.g., by publishing a report on trends in the usage of its website.

Ososoft, LLC also collects potentially personally-identifying information like Internet Protocol (IP) addresses. Ososoft, LLC does not use such information to identify its visitors, however, and does not disclose such information, other than under the same circumstances that it uses and discloses personally-identifying information, as described below. We may also collect and use IP addresses to block users who violated our [Terms of Service](/content/terms).

### Gathering of Personally-Identifying Information

Certain visitors to Ososoft, LLC's websites choose to interact with Ososoft, LLC in ways that require Ososoft, LLC to gather personally-identifying information. The amount and type of information that Ososoft, LLC gathers depends on the nature of the interaction. Ososoft, LLC collects such information only insofar as is necessary or appropriate to fulfill the purpose of the visitor's interaction with Ososoft, LLC. Ososoft, LLC does not disclose personally-identifying information other than as described below. And visitors can always refuse to supply personally-identifying information, with the caveat that it may prevent them from engaging in certain Service-related activities.

Additionally, some interactions, such as posting a comment, may ask for optional personal information. For instance, when posting a comment, may provide a website that will be displayed along with a user's name when the comment is displayed. Supplying such personal information is completely optional and is only displayed for the benefit and the convenience of the user.

### Aggregated Statistics

Ososoft, LLC may collect statistics about the behavior of visitors to the Service. For instance, Ososoft, LLC may monitor the most popular parts of the site. Ososoft, LLC may display this information publicly or provide it to others. However, Ososoft, LLC does not disclose personally-identifying information other than as described below.

### Protection of Certain Personally-Identifying Information

Ososoft, LLC discloses potentially personally-identifying and personally-identifying information only to those of its employees, contractors and affiliated organizations that (i) need to know that information in order to process it on Ososoft, LLC's behalf or to provide services available at Ososoft, LLC's websites, and (ii) that have agreed not to disclose it to others. Some of those employees, contractors and affiliated organizations may be located outside of your home country; by using the Service, you consent to the transfer of such information to them. Ososoft, LLC will not rent or sell potentially personally-identifying and personally-identifying information to anyone. Other than to its employees, contractors and affiliated organizations, as described above, Ososoft, LLC discloses potentially personally-identifying and personally-identifying information only when required to do so by law, or when Ososoft, LLC believes in good faith that disclosure is reasonably necessary to protect the property or rights of Ososoft, LLC, third parties or the public at large. If you are a registered user of the Service and have supplied your email address, Ososoft, LLC may occasionally send you an email to tell you about new features, solicit your feedback, or just keep you up to date with what's going on with Ososoft, LLC and our products. We primarily use our website and blog to communicate this type of information, so we expect to keep this type of email to a minimum. If you send us a request (for example via a support email or via one of our feedback mechanisms), we reserve the right to publish it in order to help us clarify or respond to your request or to help us support other users. Ososoft, LLC takes all measures reasonably necessary to protect against the unauthorized access, use, alteration or destruction of potentially personally-identifying and personally-identifying information.

### Cookies

A cookie is a string of information that a website stores on a visitor's computer, and that the visitor's browser provides to the Service each time the visitor returns. Ososoft, LLC uses cookies to help Ososoft, LLC identify and track visitors, their usage of Ososoft, LLC Service, and their Service access preferences. Ososoft, LLC visitors who do not wish to have cookies placed on their computers should set their browsers to refuse cookies before using Ososoft, LLC's websites, with the drawback that certain features of Ososoft, LLC's websites may not function properly without the aid of cookies.

### Data Storage

Ososoft, LLC uses third party vendors and hosting partners to provide the necessary hardware, software, networking, storage, and related technology required to run the Service. You understand that although you retain full rights to your data, it may be stored on third party storage and transmitted through third party networks.

### Privacy Policy Changes

Although most changes are likely to be minor, Ososoft, LLC may change its Privacy Policy from time to time, and in Ososoft, LLC's sole discretion. Ososoft, LLC encourages visitors to frequently check this page for any changes to its Privacy Policy. Your continued use of this site after any change in this Privacy Policy will constitute your acceptance of such change.

### Attribution

This document is based upon the [Automattic Privacy Policy](http://automattic.com/privacy/) and is licensed under [Creative Commons Attribution Share-Alike License 2.5](http://creativecommons.org/licenses/by-sa/2.5/). Basically, this means you can use it verbatim or edited, but you must release new versions under the same license and you have to credit Automattic somewhere (like this!). Automattic is not connected with and does not sponsor or endorse Ososoft, LLC or its use of the work.

Images in this document come from [unDraw](https://undraw.co/) by [Katerina Limpitsouni](https://twitter.com/ninaLimpi) under a [truly open license](https://undraw.co/license).

Ososoft, LLC makes available services including our web sites, [BeeBee.social](http://beebee.social/) and [BeeBeeSocial.com](http://beebeesocial.com/), our blog, our API, and any other software, sites, and services offered by Ososoft, LLC in connection to any of those (taken together, the "Service"). It is Ososoft, LLC's policy to respect your privacy regarding any information we may collect while operating our websites.

---
`;

export const TERMS_MARKDOWN = `
# Terms of Service

Last revised on September 1st, 2022

### The Gist

Ososoft, LLC operates the BeeBee.social service, which we hope you use. If you use it, please use it responsibly. If you don't, we'll have to terminate your account.

For paid accounts, you'll be charged on a monthly basis. You can cancel anytime, but there are no refunds.

You own the source code that you provide to BeeBee.social and you're responsible for keeping it safe.

The Terms of Service, BeeBee.social itself, and our prices can change at any time. We'll warn you 30 days in advance of any price changes. We'll try to warn you about major changes to the Terms of Service or BeeBee.social, but we make no guarantees.

That's the basic idea, but You must read through the entire Terms of Service below and agree with all the details before You use any of our sites (whether or not you have created an account).

### Your Agreement with Ososoft, LLC

Your use of the Ososoft, LLC service is governed by this agreement (the "Terms"). "The company" means Ososoft, LLC. The "Service" means the services Ososoft, LLC makes available include our web sites, [BeeBee.social](http://beebee.social/) and [BeeBeeSocial.com](http://beebeesocial.com/), our blog, our API, and any other software, sites, and services offered by Ososoft, LLC in connection to any of those. "Customer Source Code" means any source code you submit to Ososoft, LLC for the purpose of using the Service. "Content" means all content generated by Ososoft, LLC on your behalf (including metric data) and does not include Customer Source Code.

In order to use the Service, You (the "Customer", "You", or "Your") must first agree to the Terms. You understand and agree that Ososoft, LLC will treat Your use of the Service as acceptance of the Terms from that point onwards.

Ososoft, LLC may make changes to the Terms from time to time. You may reject the changes by terminating Your account. You understand and agree that if You use the Service after the date on which the Terms have changed, Ososoft, LLC will treat Your use as acceptance of the updated Terms.

If you have any question about the Terms, please contact us at hello@ososoft.net.

### Your Account

* You may not use the Service if You are a person barred from receiving the Service under the laws of the United States or other countries, including the country in which You are resident or from which You use the Service.
* You may not use the service unless you are over the age of 13.
* You must be a human. Accounts created by automated methods are not permitted.

### Use of the Service

* You must provide accurate and complete registration information any time You register to use the Service.
* You are responsible for the security of Your passwords and for any use of Your account.
* Your use of the Service must comply with all applicable laws, regulations and ordinances.
* You agree to not engage in any activity that interferes with or disrupts the Service.
* Ososoft, LLC reserves the right to enforce quotas and usage limits (to any resources, including the API) at its sole discretion, with or without notice, which may result in Ososoft, LLC disabling or throttling your usage of the Service for any amount of time.
* You may not allow multiple people to use the same account or otherwise access the Service in a manner intended to avoid incurring fees.

### Service Policies and Privacy

The Service shall be subject to the privacy policy for the Service available at privacy policy. You agree to the use of Your data in accordance with Ososoft, LLC's privacy policies.

### Fees for Use of the Service

* The Service may be provided to You without charge up with certain limits or for a certain "trial" period of time.
* Usage over this limit (or after the "trial" period) requires Your purchase of additional resources or services.
* For all purchased resources and services, we will charge Your credit card on a monthly basis.
* Payments are non-refundable. There will be no refunds or credits for partial months of service, upgrade/downgrade refunds, or refunds for months unused with an open account.
* Charges are solely based on Ososoft, LLC's measurements of Your use of the Service, unless otherwise agreed to in writing.
* All fees are exclusive of all taxes, levies, or duties imposed by taxing authorities, and You shall be responsible for payment of all such taxes, levies, or duties.
* You acknowledge and agree that any credit card and related billing and payment information that You provide to Ososoft, LLC may be shared by Ososoft, LLC with companies who work on Ososoft, LLC's behalf, such as payment processors and/or credit agencies, solely for the purposes of checking credit, effecting payment to Ososoft, LLC and servicing Your account.
* Ososoft, LLC may change its fees and payment policies for the Service by notifying You at least thirty (30) days before the beginning of the billing cycle in which such change will take effect.

### Cancellation and Termination

* You must cancel Your account via [the settings page](http://beebee.social/settings). You must be logged in to view this page. An email or phone request to cancel Your account is not considered cancellation.
* You will not receive any refunds if You cancel Your account.
* If You cancel the Service before the end of Your current paid up month, Your cancellation will take effect immediately and You will not be charged again.
* All of Customer Source Code and Content will, within a reasonable amount of time to be determined solely by Ososoft, LLC, be deleted from the Service upon cancellation.
* You agree that Ososoft, LLC, in its sole discretion and for any or no reason, may terminate or suspend Your account. You agree that any termination of Your access to the Service may be without prior notice, and You agree that Ososoft, LLC will not be liable to You or any third party for such termination.

### Customer Source Code

* Ososoft, LLC claims no ownership or control over any Customer Source Code. You retain copyright and any other rights You already hold in the Customer Source Code and You are responsible for protecting those rights, as appropriate.
* You agree to assume full responsibility for configuring the Service to allow appropriate access to any Customer Source code provided to the Service.
* You understand that public projects display Customer Source Code to any party on the Internet, including search engines and web spiders.
* You understand that private projects will display Customer Source Code to You and any collaborators that you designate for that project.
* You retain sole responsibility for any collaborators or third-party services that you allow to view Customer Source Code and entrust them at your own risk.
* Ososoft, LLC is not responsible if you fail to configure, or misconfigure, your project and inadvertently allow unauthorized parties view any Customer Source Code.

### Ideas and Feedback

You may choose to or we may invite You to submit comments or ideas about the Service, including but not limited to ideas about improving the Service or our products ("Ideas"). By submitting any Idea, You agree that Your disclosure is unsolicited and without restriction and will not place Ososoft, LLC under any fiduciary or other obligation, and that we are free to use the Idea without any additional compensation to You, and/or to disclose the Idea on a non-confidential basis or otherwise to anyone.

### Modification of the Service

* You acknowledge and agree that the Service may change from time to time without prior notice to You.
* Changes include, without limitation, changes to fee and payment policies, security patches, added or removed functionality, and other enhancements or restrictions.
* Ososoft, LLC shall not be liable to you or to any third party for any modification, price change, suspension or discontinuance of the Service.

### External Resources

The Services may include hyperlinks to other web sites or content or resources or email content. You acknowledge and agree that Ososoft, LLC is not responsible for the availability of any such external sites or resources, and does not endorse any advertising, products or other materials on or available from such web sites or resources.

### License from Ososoft, LLC and Restrictions

Ososoft, LLC gives You a personal, worldwide, royalty-free, non-assignable and non-exclusive license to use the software provided to You by Ososoft, LLC as part of the Service as provided to You by Ososoft, LLC. This license is for the sole purpose of enabling You to use and enjoy the benefit of the Service as provided by Ososoft, LLC, in the manner permitted by the Terms.

You may not (and You may not permit anyone else to): (a) copy, modify, create a derivative work of, reverse engineer, decompile or otherwise attempt to extract the source code of the Service or any part thereof, unless this is expressly permitted or required by law, or unless You have been specifically told that You may do so by Ososoft, LLC, in writing (e.g., through an open source software license); or (b) attempt to disable or circumvent any security mechanisms used by the Service.

Open source software licenses for components of the Service released under an open source license constitute separate written agreements. To the limited extent that the open source software licenses expressly supersede these Terms, the open source licenses govern Your agreement with Ososoft, LLC for the use of the components of the Service released under an open source license.

### Exclusion of warranties

* You expressly understand and agree that your use of the service is at your sole risk and that the service is provided "as is" and "as available.".
* You agree that Ososoft, LLC has no responsibility or liability for the deletion or failure to store any Content and other communications maintained or transmitted through use of the Service. You further acknowledge that You are solely responsible for securing and backing up Customer Source Code.
* Ososoft, LLC does not warrant to you that: (a) your use of the service will meet your requirements, (b) your use of the service will be uninterrupted, timely, secure or free from error, (c) the results or data provided by the Service will be accurate, (d) the quality of the service will meet your expectations and (e) any errors in the Service will be fixed.

### Limitation of liability

You expressly understand and agree that Ososoft, LLC, its subsidiaries and affiliates, and its licensors shall not be liable to you for any direct, indirect, incidental, special consequential or exemplary damages which may be incurred by you, however caused and under any theory of liability. This shall include, but not be limited to, any loss of profit (whether incurred directly or indirectly), any loss of goodwill or business reputation, any loss of data suffered, cost of procurement of substitute goods or services, or other intangible loss (whether or not Ososoft, LLC has been advised of or should have been aware of the possibility of any such losses arising).

### Indemnification

You agree to hold harmless and indemnify Ososoft, LLC, and its subsidiaries, affiliates, officers, agents, employees, advertisers, licensors, suppliers or partners (collectively "Ososoft, LLC and Partners") from and against any third party claim arising from or in any way related to (a) Your breach of the Terms, (b) Your use of the Service, (c) Your violation of applicable laws, rules or regulations in connection with the Service, or (d) Your Customer Source Code, including any liability or expense arising from all claims, losses, damages (actual and consequential), suits, judgments, litigation costs and attorneys' fees, of every kind and nature. In such a case, Ososoft, LLC will provide You with written notice of such claim, suit or action.

### General Legal Terms

The Terms constitute the whole legal agreement between You and Ososoft, LLC and govern Your use of the Service and completely replace any prior agreements between You and Ososoft, LLC in relation to the Service.

You agree that if Ososoft, LLC does not exercise or enforce any legal right or remedy which is contained in the Terms (or which Ososoft, LLC has the benefit of under any applicable law), this will not be taken to be a formal waiver of Ososoft, LLC's rights and that those rights or remedies will still be available to Ososoft, LLC.

Ososoft, LLC shall not be liable for failing or delaying performance of its obligations resulting from any condition beyond its reasonable control, including but not limited to, governmental action, acts of terrorism, earthquake, fire, flood or other acts of God, labor conditions, power failures, and Internet disturbances.

### Attribution

This document is an adaptation of the [Heroku Terms of Service](http://legal.heroku.com/tos), which is turn an adaptation of the [Google App Engine Terms of Service](http://code.google.com/appengine/terms.html). The original work has been modified with permission under the [Creative Commons Attribution 3.0 License](http://creativecommons.org/licenses/by/3.0/). Neither Heroku, Inc. nor Google, Inc. is connected with or sponsor or endorse Ososoft, LLC or its use of the work.

You're welcome to adapt and use this document for your own needs. If you make an improvement, we'd appreciate it if you would let us know so we can consider improving our own document.

Images in this document come from [unDraw](https://undraw.co/) by [Katerina Limpitsouni](https://twitter.com/ninaLimpi) under a [truly open license](https://undraw.co/license).

---
`;

export const ABOUT_MARKDOWN = `
# About Us

The BeeBee.social team is a small collection of experienced developers. We pull in the best features from many sites into one place, then add several new features of our own. If you want to know more about how [BeeBee.social](/buzz) came to be, what drives us, and what the future holds, keep reading.

### The Company

Ososoft, LLC (the parent company of BeeBee.social) is a consulting company formed in 2016. It was the second such venture by [Joseph Hall (@groundh0g)](/user/groundh0g), a 30-year veteran of the software development industry, working with big-name and no-name companies on server, mobile, data, web, desktop, CLI, and embedded code.

### The Evolution

Joe has been writing several SPA web apps that don't depend on any backing services. This work has been focused mainly on game development tools that run on any platform, in any browser. One of his many tangent ideas is for a social media site to curate, collaborate, rate, share, and provide alternates for links. This tangent to that tangent is to create the plumbing for the social aspects of the site, with the link sharing module to come soon after.

### The Customer

Whether you're an individual, a family, a small team, a group of enthusiasts, a brand, or a large enterprise, BeeBee.social has you covered. We're building features for all those use cases, and more. The site will always offer a free, ad-supported tier, but also offers many levels of reasonably-priced plans to add related features and kill the ads.

### The Growth Plan

Security and performance for websites is serious business. The prototype for BeeBee.social avoids those facets in its pre-release state. As the project matures, we'll start sending out invitations to join. Once your account is created and verified, you'll be given a number of invitations to send to your friends and associates if you ike what you see.

As you kick the tires and get settled in, please feel free to share your thoughts, issues, and ideas with us at [hello@ososoft.net](mailto:hello@ososoft.net). We appreciate your input, and we'll try to respond to every email we receive.

### Attribution

Images in this document come from [unDraw](https://undraw.co/) by [Katerina Limpitsouni](https://twitter.com/ninaLimpi) under a [truly open license](https://undraw.co/license).

---
`;

export const COMMUNITY_MARKDOWN = `
# Community Guidelines

The goals of this community are ... blah.

### Guidelines

You do not have the right to never be offended, but you do have the right to be treated with respect.

* Treat others online as you would treat them in real life.
* Be tolerant. When the viewpoints of community members do not align, respectfully disagree.
* Engage with others in the community in a courtious and respectful manner.
* Respect the privacy and personal information of others.
* Don't post information or commentary that you don't want others to see. This is the internet, folks. And the internet is forever.

Unfortunately, the internet provides a sense of anonymity, which leads many to abandon basic decency and decorum.

* Do not make personal attacks against community members.
* Do not defame or make false statements against community members.
* Do not bully or troll community members.
* Avoid prejudiced comments and/or profanity, damnit.

There are some things that shouldn't need to be said, but for those that aren't familiar with the obvious rules of every community, the following is presented as a reminder.

* Do not engage in illegal activities. This includes international, federal, state, and local laws applicable to your locale.
* Do not abuse the site in a way that compromises its availability or security.
* Do not attempt to violate the privacy of community members, or attempt to access information about them that is not publicly available.

### Agreement

By logging into this site and using its services, you are considered to be in explicit agreement with the guidelines listed above.

### Consequences

Violation of these basic guidelines may result in action against the offending member(s) ranging from a warning, to suspension of privileged access to the site and services, to revocation of their account entirely. Please report behavior that violates these terms to [community@ososoft.net](mailto:community@ososoft.net). Be sure to include the username of the member(s) and a link to the offending content.

### Attribution

Images in this document come from [unDraw](https://undraw.co/) by [Katerina Limpitsouni](https://twitter.com/ninaLimpi) under a [truly open license](https://undraw.co/license).

---
`;