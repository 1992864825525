import { useHistory } from 'react-router';
//import { useNavigate } from 'react-router-dom';

export default class AuthUtil {

    public static AUTH_STATE_KEY = "AUTH_STATE";

    public static Logout() {
        console.log('AuthUtil.Logout()');
        sessionStorage.setItem(AuthUtil.AUTH_STATE_KEY, JSON.stringify({
            isLoggedIn: false,
            userName: "",
            credentials: "",
        }));
    }

    public static RequireAuth(isRequired: boolean = true) {
        return !isRequired || AuthUtil.isLoggedIn();
    }

    public static HideClassNameIfLoggedIn() {
        return AuthUtil.isLoggedIn() ? "hidden" : "";
    }

    public static HideClassNameIfNotLoggedIn() {
        return AuthUtil.isLoggedIn() ? "" : "hidden";
    }

    public static Login(userName: string, credentials: string = "") {
        console.log(`AuthUtil.Login('${userName}', '${credentials}')`);
        sessionStorage.setItem(AuthUtil.AUTH_STATE_KEY, JSON.stringify({
            isLoggedIn: true,
            userName: userName,
            credentials: credentials,
        }));
    }

    public static getState() {
        return JSON.parse(sessionStorage.getItem(AuthUtil.AUTH_STATE_KEY) || "{}");
    }

    public static isLoggedIn() {
        return AuthUtil.getState().isLoggedIn;
    }

    public static getUserName() {
        return AuthUtil.getState().userName || "";
    }

    private static getCredentials() {
        return AuthUtil.getState().credentials || "";
    }
}
 