import * as React from 'react';
import { format, formatDistanceToNow } from 'date-fns';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Row, Col, ButtonGroup, Input, Button, Form, FormGroup } from 'reactstrap';
import { ApplicationState } from '../../store';
import * as LoginStore from '../../store/Login';
// import loginImage from './images/bee-dialog-login.png';
// import loginImageResponsive from './images/bee-dialog.png';
import MessageConversationItem from '../../store/MessagesConversationItem';
//import SidebarLeft from '../stream-items/SidebarLeft';
//import 'bootstrap-icons/font/bootstrap-icons.css';
import './MessagesViewItem.css';
import App from '../../App';
import AuthUtil from '../../store/AuthUtil';

export default class MessagesViewItem {
    static count = 0;

    private static renderUnreadMarker(doRender: boolean) {
        if (doRender) {
            return (<div className="unreadMarker">Unread Message(s)</div>);
        } else {
            return (<></>);
        }

    }

    public static render(item: MessageConversationItem, userName: string, doRenderUnreadMarker: boolean = false) {
        const source = item.userName === AuthUtil.getUserName() ? "self" : "them";
        const date = new Date(item.date);

        return (
            <>
                { MessagesViewItem.renderUnreadMarker(doRenderUnreadMarker) }
                <div className="messages-view-item" key={2000 + MessagesViewItem.count++}>
                    <div className={`messages-view-item-${source}`}>
                        <div className="messages-view-item-date"><strong>{item.displayName}:</strong> {formatDistanceToNow(date)} ago, {format(date, 'PPPPp')}</div>
                        <img alt={item.displayName} title={`${item.displayName} \n@${item.userName}`} src={item.avatarDataUri} />
                        <div className="messages-view-item-text">{item.summary}</div>
                    </div>
                </div>
            </>
        );
    };
};
