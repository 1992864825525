import * as StreamItemsBookmarks from './StreamItemsBookmarks';
import * as StreamItemsBuzz from './StreamItemsBuzz';
import * as StreamItemsExplore from './StreamItemsExplore';
import * as StreamItemsLists from './StreamItemsLists';
import * as StreamItemsLocal from './StreamItemsLocal';
import * as StreamItemsProfile from './StreamItemsProfile';
import * as Messages from './Messages';
import * as Login from './Login';

// The top-level state object
export interface ApplicationState {
    streamItemsBookmarks: StreamItemsBookmarks.StreamItemsState | undefined;
    streamItemsBuzz: StreamItemsBuzz.StreamItemsState | undefined;
    streamItemsExplore: StreamItemsExplore.StreamItemsState | undefined;
    streamItemsLists: StreamItemsLists.StreamItemsState | undefined;
    streamItemsLocal: StreamItemsLocal.StreamItemsState | undefined;
    streamItemsProfile: StreamItemsProfile.StreamItemsState | undefined;
    // streamItems: StreamItems.StreamItemsState | undefined;
    conversations: Messages.MessagesState | undefined;
    login: Login.LoginState | undefined;
}

// Whenever an action is dispatched, Redux will update each top-level application state property using
// the reducer with the matching name. It's important that the names match exactly, and that the reducer
// acts on the corresponding ApplicationState property type.
export const reducers = {
    streamItemsBookmarks: StreamItemsBookmarks.reducer,
    streamItemsBuzz: StreamItemsBuzz.reducer,
    streamItemsExplore: StreamItemsExplore.reducer,
    streamItemsLists: StreamItemsLists.reducer,
    streamItemsLocal: StreamItemsLocal.reducer,
    streamItemsProfile: StreamItemsProfile.reducer,
    // streamItems: StreamItems.reducer,
    conversations: Messages.reducer,
    login: Login.reducer,
};

// This type can be used as a hint on action creators so that its 'dispatch' and 'getState' params are
// correctly typed to match your store.
export interface AppThunkAction<TAction> {
    (dispatch: (action: TAction) => void, getState: () => ApplicationState): void;
}
