import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Row, Col, ButtonGroup, Input } from 'reactstrap';
import { Link } from 'react-router-dom';
import { ApplicationState } from '../store';
import * as StreamItemsStore from '../store/StreamItemsLists';
import SidebarLeft from './stream-items/SidebarLeft';
import SidebarRight from './stream-items/SidebarRight';
import StreamContent from './stream-items/StreamContent';
// import StreamItemBase from './ItemStreamBase';
import AuthUtil from '../store/AuthUtil';

// At runtime, Redux will merge together...
type StreamItemProps =
    StreamItemsStore.StreamItemsState // ... state we've requested from the Redux store
    & typeof StreamItemsStore.actionCreators // ... plus action creators we've requested
    & RouteComponentProps<{ startIndex: string, userName: string }>; // ... plus incoming routing parameters


class FetchData extends React.PureComponent<StreamItemProps> {
    // This method is called when the component is first added to the document
    public componentDidMount() {
        this.ensureDataFetched();
    }

    // This method is called when the route parameters change
    public componentDidUpdate() {
        this.ensureDataFetched();
    }

    protected ensureDataFetched() {
        const startIndex = parseInt(this.props.match.params.startIndex, 10) || 0;
        const userName = this.props.match.params.userName;
        //const apiUri = `/user/${userName}/lists/${startIndex}`;
        this.props.requestStreamItems(startIndex, userName);
    }

    public render() {
        if (AuthUtil.RequireAuth()) {
            return (
                <Row className='tall'>
                    <Col md='3'> <SidebarLeft></SidebarLeft> </Col>
                    <Col md='5'> { StreamContent.render(this.props.items, this.props.userName) } </Col>
                    <Col md='4'> { SidebarRight.render(this.props.items, this.props.userName) } </Col>
                </Row>
            );
        } else {
            return (
                <Row className='tall'>
                    <Col md='3'> <SidebarLeft></SidebarLeft> </Col>
                    <Col md='5'>
                        <h2><i className="bi bi-arrow-bar-left"></i> Login Required</h2>
                        <p>You must be logged in to view this content.<br />
                       Click the <i className="bi bi-person-circle"></i>&nbsp;<strong>Login</strong> link to the left of this page.</p>
                    </Col>
                    <Col md='4'>&nbsp;</Col>
                </Row>
            );
        }
    }
}

export default connect(
    (state: ApplicationState) => state.streamItemsLists, // Selects which state properties are merged into the component's props
    StreamItemsStore.actionCreators // Selects which action creators are merged into the component's props
)(FetchData as any);

