import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Redirect, Link } from 'react-router-dom';
import { Row, Col, ButtonGroup, Input } from 'reactstrap';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import loginImage from './images/bee-dialog-login.png';
import loginImageResponsive from './images/bee-dialog.png';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Login.css';
import App from '../App';
import AuthUtil from '../store/AuthUtil';
import './Content.css';

type LoginProps =
    LoginStore.LoginState &
    typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

class Login extends React.PureComponent<LoginProps> {
    private RedirectAfterLogin() {
        this.props.history.push('/buzz');
    }


    public render() {
        if (AuthUtil.isLoggedIn()) {
            return (<Redirect to={'/buzz'} />);
        } else {
            return (
                <div>
                    <Row>
                        <Col className="login-panel-left-image" xs="12" sm="12" md="6" lg="6" xl="6">
                            <img src="/images/undraw-svg/undraw_secure_login_pdn4.svg" className="content-image-full image-right" /> 
                        </Col>
                        <Col id="login-panel" xs="12" sm="12" md="6" lg="6" xl="6">
                            <hr />
                            <Input id="txtUser" type="text" placeholder="username, email address, or phone number" defaultValue="groundh0g" />
                            <Input id="txtPass" type="password" placeholder="password" />
                            <Row className="no-gutters">
                                <Col className="left-button-col" xs="6">
                                    <button type="button"
                                        className="btn btn-primary btn-lg full-width-left"
                                        onClick={() => { this.props.doLogin(this); this.RedirectAfterLogin(); }}>
                                        <i className="bi bi-person-plus-fill">&nbsp;</i> Sign Up
                                    </button>
                                </Col>
                                <Col className="right-button-col" xs="6">
                                    <button type="button"
                                        className="btn btn-outline-primary btn-lg full-width-right"
                                        onClick={() => { this.props.doLogin(this); this.RedirectAfterLogin(); }}>
                                        <i className="bi bi-lock-fill">&nbsp;</i> Sign In
                                    </button>
                                </Col>
                            </Row>

                            { /*

                            <hr />

                            <p>
                                ... or login using one of the following providers.
                            </p>

                            <button type="button"
                                className="btn btn-danger btn-lg third-party-auth"
                                onClick={() => { this.props.doLogin(this); }}>
                                <i className="bi bi-google">&nbsp;</i> Google
                            </button>

                            <button type="button"
                                className="btn btn-primary btn-lg third-party-auth"
                                onClick={() => { this.props.doLogin(this); }}>
                                <i className="bi bi-facebook">&nbsp;</i> Facebook
                            </button>

                            <button type="button"
                                className="btn btn-info btn-lg third-party-auth"
                                onClick={() => { this.props.doLogin(this); }}>
                                <i className="bi bi-twitter">&nbsp;</i> Twitter
                            </button>

                            <button type="button"
                                className="btn btn-dark btn-lg third-party-auth"
                                onClick={() => { this.props.doLogin(this); }}>
                                <i className="bi bi-apple">&nbsp;</i> Apple
                            </button>

                            <button type="button"
                                className="btn btn-success btn-lg third-party-auth"
                                onClick={() => { this.props.doLogin(this); }}>
                                <i className="bi bi-windows">&nbsp;</i> Microsoft
                            </button>

                            */ }

                            <hr />

                            <p>
                                View our <Link to="/content/privacy">privacy policy</Link> and <Link to="/content/community">community guidelines</Link>.
                            </p>

                            <hr />
                        </Col>
                    </Row>
                </div>
            )
        }

    };
};

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(Login);
