import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
import App from '../App';
import AuthUtil from '../store/AuthUtil';
import { Redirect } from 'react-router-dom';

type LoginProps =
    // LoginStore.LoginState &
    // typeof LoginStore.actionCreators &
    RouteComponentProps<{}>;

class FetchData extends React.PureComponent<LoginProps> {

    // This method is called when the component is first added to the document
    public render() {
        AuthUtil.Logout();
        return (<Redirect to={'/'} />);
    };
};

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(FetchData as any);
