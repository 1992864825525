import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Row, Col, ButtonGroup, Input } from 'reactstrap';
import { ApplicationState } from '../store';
import * as LoginStore from '../store/Login';
// import loginImage from './images/bee-dialog-login.png';
// import loginImageResponsive from './images/bee-dialog.png';
import SidebarLeft from './stream-items/SidebarLeft';
import 'bootstrap-icons/font/bootstrap-icons.css';
import './Subscribe.css';
import App from '../App';
import { PRIVACY_MARKDOWN } from '../store/PolicyUtil';
import * as showdown from 'showdown';
import './Content.css';


type ContentProps = RouteComponentProps<{}>;

class ContentPrivacy extends React.PureComponent<ContentProps> {
    public render() {
        const converter = new showdown.Converter();
        return (
            <Row className='tall'>
                <Col md='3'> <SidebarLeft></SidebarLeft> </Col>
                <Col md='9'>
                    <Row>
                        <Col md="12">
                            <img src="/images/undraw-svg/undraw_private_data_re_4eab.svg" className="content-image image-right" /> 
                            <div dangerouslySetInnerHTML={{ __html: converter.makeHtml(PRIVACY_MARKDOWN) }}></div>
                        </Col>
                    </Row>
                </Col>
            </Row>
        );
    };
};

export default connect(
    (state: ApplicationState) => state.login,
    LoginStore.actionCreators
)(ContentPrivacy);
