import { Action, Reducer } from 'redux';
import { AppThunkAction } from './';
import StreamItem from './StreamItem';
import PlatformUtil from './PlatformUtil';

// -----------------
// STATE - This defines the type of data maintained in the Redux store.

export interface StreamItemsState {
    isLoading: boolean;
    startIndex?: number;
    items: StreamItem[];
    userName: string;
}

// -----------------
// ACTIONS - These are serializable (hence replayable) descriptions of state transitions.
// They do not themselves have any side-effects; they just describe something that is going to happen.

interface RequestStreamItemsAction {
    type: 'REQUEST_ITEM_STREAM_PROFILE';
    startIndex: number;
    userName: string;
}

interface ReceiveStreamItemsAction {
    type: 'RECEIVE_ITEM_STREAM_PROFILE';
    startIndex: number;
    items: StreamItem[];
    userName: string;
}

// Declare a 'discriminated union' type. This guarantees that all references to 'type' properties contain one of the
// declared type strings (and not any other arbitrary string).
type KnownAction = RequestStreamItemsAction | ReceiveStreamItemsAction;

// ----------------
// ACTION CREATORS - These are functions exposed to UI components that will trigger a state transition.
// They don't directly mutate state, but they can have external side-effects (such as loading data).

export const actionCreators = {
    requestStreamItems: (startIndex: number, userName: string): AppThunkAction<KnownAction> => (dispatch, getState) => {
        // Only load data if it's something we don't already have (and are not already loading)
        const appState = getState();
        if (appState && appState.streamItemsProfile && (startIndex !== appState.streamItemsProfile.startIndex || appState.streamItemsProfile.userName != userName)) {
            const fetchUri = PlatformUtil.SanitizeApiUrl(`/api/user/${userName}/profile/${startIndex || 0}`);

            fetch(fetchUri)
                .then(response => response.json() as Promise<StreamItem[]>)
                .then(data => {
                    dispatch({ type: 'RECEIVE_ITEM_STREAM_PROFILE', startIndex: startIndex, userName: userName, items: data });
                });

            dispatch({ type: 'REQUEST_ITEM_STREAM_PROFILE', startIndex: startIndex, userName: userName });
        }
    }
};

// ----------------
// REDUCER - For a given state and action, returns the new state. To support time travel, this must not mutate the old state.

const unloadedState: StreamItemsState = { items: [], userName: "", isLoading: false };

export const reducer: Reducer<StreamItemsState> = (state: StreamItemsState | undefined, incomingAction: Action): StreamItemsState => {
    if (state === undefined) {
        return unloadedState;
    }

    const action = incomingAction as KnownAction;
    switch (action.type) {
        case 'REQUEST_ITEM_STREAM_PROFILE':
            return {
                startIndex: state.startIndex,
                items: state.items,
                userName: state.userName,
                isLoading: true
            };
        case 'RECEIVE_ITEM_STREAM_PROFILE':
            // Only accept the incoming data if it matches the most recent request. This ensures we correctly
            // handle out-of-order responses.
            //if (action.startIndex === state.startIndex) {
                return {
                    startIndex: action.startIndex,
                    items: action.items,
                    userName: action.userName,
                    isLoading: false
                };
            //}
            break;
    }

    return state;
};

