import * as React from 'react';
import { connect } from 'react-redux';
import { RouteComponentProps } from 'react-router';
import { Row, Col, ButtonGroup, Form, FormGroup, Input, Button } from 'reactstrap';
import { ApplicationState } from '../../store';
import * as LoginStore from '../../store/Login';
import MessagesConversation from '../../store/MessagesConversation';
import MessagesParticipant from '../../store/MessagesParticipant';
import MessagesListItem from './MessagesListItem';
// import * as MessagesStore from '../../store/Messages';
// import loginImage from './images/bee-dialog-login.png';
// import loginImageResponsive from './images/bee-dialog.png';
// import SidebarLeft from '../stream-items/SidebarLeft';
// import 'bootstrap-icons/font/bootstrap-icons.css';
import './MessagesList.css';
import App from '../../App';

//type MessagesListProps =
//    MessagesStore.MessagesState &
//    typeof MessagesStore.actionCreators &
//    RouteComponentProps<{}>;

export default class MessagesList { // extends React.PureComponent<MessagesListProps> {
    public static render(conversations: MessagesConversation[], selectConversation: any, selectedConversationIndex: string, userName: string) {
        const selectedIndex: number = parseInt(selectedConversationIndex, 10);
        MessagesListItem.listItemIndex = 0;
        return (
            <>
                <Form inline>
                    <FormGroup>
                        <Input type="text" name="search" id="txtSearch" placeholder="Search Messages" />
                        <Button id="cmdSearch" color="primary" title="Click to search"><i className="bi bi-search"></i></Button>
                        <Button id="cmdNewConversation" color="primary" title="Start a New Conversation"><i className="bi bi-chat-dots-fill"></i></Button>
                    </FormGroup>
                </Form>

                <div className="messages-list">
                    <div className="messages-list-item-wrapper">
                        {conversations.map((conversation: MessagesConversation, index: number) => {
                            return (
                                MessagesListItem.render(conversation.participants, selectConversation, isNaN(selectedIndex) ? -1 : selectedIndex, conversation.isUnread, userName)
                            );
                        })}
                    </div>

                    <p>&nbsp;</p>
                </div>
            </>
        );

    };
};

//export default connect(
//    (state: ApplicationState) => state.login,
//    LoginStore.actionCreators
//)(MessagesList);
