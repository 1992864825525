import React from 'react';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter, Row, Col, Container } from 'reactstrap';
import MDEditor from '@uiw/react-md-editor';
import rehypeSanitize from 'rehype-sanitize';
import './NewStreamItemModal.css';

//import { StacksEditor } from "@stackoverflow/stacks-editor";
//// don't forget to include the styles as well
//import "@stackoverflow/stacks-editor/dist/styles.css";
//// include the Stacks js and css as they're not included in the bundle
//import "@stackoverflow/stacks";
//import "@stackoverflow/stacks/dist/css/stacks.css";

export default class NewStreamItemModal extends React.Component<{ show: boolean, value: string, hideModal: any }, { show: boolean, value: string }> {
    // const[value, setValue] = React.useState("");

    //public set value(v) { this.setState(value: v); }
    public value() { return this.state.value; }
    public setValue(v: string) { this.setState({ value: v }); }

    constructor(props: any) {
        super(props);

        this.state = {
            show: props.show,
            value: props.value,
        };

        this.savePost = this.savePost.bind(this);
        this.setValue = this.setValue.bind(this);
        this.value = this.value.bind(this);
    }

    componentDidMount() {
        //const editor = new Editor({
        //    el: document.querySelector('#new-stream-item-modal-content-div') as HTMLElement,
        //    height: '500px',
        //    initialEditType: 'markdown',
        //    previewStyle: "tab"
        //});

        ////editor.getMarkdown();

        ////new StacksEditor(
        ////    document.querySelector("#new-stream-item-modal-content-div"),
        ////    "*Your* **markdown** here",
        ////    {}
        ////);
    }

    savePost() {
        // TODO: persist post to data store
        this.props.hideModal();
    }

    render(): React.ReactFragment {
        return (
            <div>
            <Modal isOpen={ this.props.show } toggle={ this.props.hideModal } className="new-stream-item-modal" centered={ true } size="lg" >
                <ModalHeader toggle={ this.props.hideModal }>New Post</ModalHeader>
                    <ModalBody>
                        {/* <MDEditor value={this.state.value} onChange={this.setValue} previewOptions={{ rehypePlugins: [[rehypeSanitize]] }} /> */}
                        {/* <MDEditor.Markdown source={this.state.value} style={{"white-space": 'pre-wrap'}} /> */}
                    </ModalBody>
                    <ModalFooter>
                        <Container fluid className="new-stream-item-modal-footer-icons">
                            <Row>
                                <Col md="6">
                                    <div>
                                        <Button outline color="primary"><i className="bi bi-image" > </i></Button> { ' ' }
                                        <Button outline color="primary"> <i className="bi bi-youtube" > </i></Button> { ' ' }
                                        <Button outline color="primary"> <i className="bi bi-person-video" > </i></Button> { ' ' }
                                        <Button outline color="primary"> <i className="bi bi-bar-chart-line" > </i></Button> { ' ' }
                                        <Button outline color="primary"> <i className="bi bi-emoji-laughing" > </i></Button> { ' ' }
                                    </div>
                                </Col>
                                <Col md="6"><div className="text-right">
                                    <Button color="primary" onClick={ this.savePost }> Save Post </Button>{ ' ' }
                                    <Button outline color="secondary" onClick={ this.props.hideModal }> Cancel </Button>
                                </div></Col>
                            </Row>
                        </Container>
                    </ModalFooter>
                </Modal>
            </div>
        );
    }
}
